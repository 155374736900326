import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BannerContainerComponent } from '../../../shared/components';
import { Router } from '@angular/router';
import { TenantService } from '../../../../services';
import { filter, Subject, takeUntil, tap } from 'rxjs';

@Component({
  templateUrl: './no-tenant-error.component.html',
  styleUrls: ['./no-tenant-error.component.scss'],
})
export class NoTenantErrorComponent implements OnInit, OnDestroy {
  /**
   * General banner container used by all views.
   */
  @ViewChild(BannerContainerComponent, { static: true })
  public bannerContainer!: BannerContainerComponent;

  private onDestroy = new Subject<void>();

  constructor(
    private readonly router: Router,
    private readonly tenantService: TenantService
  ) {}

  ngOnInit(): void {
    this.bannerContainer.showError(
      `The authenticated user has no tenant assigned. This configuration error requires an Alteo employee to solve. Please contact support for assistance.`,
      { closable: false }
    );

    /**
     * This stream handles tenant list change.
     * When tenant list is not empty we redirect the user to root.
     */
    this.tenantService.tenantList
      .pipe(
        takeUntil(this.onDestroy),
        filter(tenantList => tenantList.length !== 0),
        tap(() => void this.router.navigate(['/']))
      )
      .subscribe();
  }

  /**
   * Triggers user's tenant list refresh.
   */
  public retry() {
    this.tenantService.refreshTenants();
  }

  ngOnDestroy(): void {
    this.onDestroy.next(undefined);
    this.onDestroy.complete();
  }
}
