import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { TenantService } from '../../../services';

/**
 * Checks if the currently selected tenant is the virtual see-all tenant.
 * In that case, the guard stops navigation to pages that do not support the tenant.
 */
@Injectable()
export class SeeAllTenantGuard {
  /**
   * The url path to which we want to return if an acceptable tenant is selected.
   */
  public redirectPath: string | undefined = '';

  constructor(
    private readonly tenantService: TenantService,
    private readonly router: Router
  ) {}

  canActivateChild(childRoute: ActivatedRouteSnapshot) {
    const currentTenant = this.tenantService.selectedTenant.value;

    if (childRoute.routeConfig?.path !== '') {
      this.redirectPath = childRoute.routeConfig?.path;
    }

    if (currentTenant.identifier === 'virtual-see-all') {
      const isVirtualSeeAllTenantSupported: boolean = childRoute.routeConfig?.data?.[
        'virtualSeeAllTenantSupported'
      ] as boolean;
      /**
       * Existence of childRoute.routeConfig?.loadChildren is checked, because we lazy loads view modules, this
       * first the `childRoute.routeConfig?.data?` will be undefined as we do not define any data in the `{@link AppRoutingModule}`.
       * After the module is loaded, then data available as it is defined in the corresponding routing-module file.
       */
      if (!childRoute.routeConfig?.loadChildren && !isVirtualSeeAllTenantSupported) {
        return this.router.parseUrl(`see-all-tenant-unsupported?redirect=/${this.redirectPath}`);
      }
    }

    return true;
  }
}
