import { NgModule } from '@angular/core';
import { SignOutSuccessComponent } from './views/sign-out-success/sign-out-success.component';
import { SignInFailedComponent } from './views/sign-on-failed/sign-in-failed.component';
import { MaterialModule } from '../../material.module';
import { RolesService, UserService } from './services';
import { RoleGuard, RoleLoaderGuard, SeeAllTenantGuard } from './guards';
import { ForbiddenPageComponent } from './views/forbidden-page/forbidden-page.component';
import { RolesResolver } from './resolvers';
import { NoTenantErrorComponent } from './views/no-tenant-error/no-tenant-error.component';
import { SharedModule } from '../shared/shared.module';
import { AuthRoutingModule } from './auth-routing.module';
import { SeeAllTenantUnsupportedPageComponent } from './views/see-all-tenant-unsupported-page/see-all-tenant-unsupported-page.component';
import { TenantLoaderGuard } from './guards/tenant-loader.guard';
import { SignInComponent } from './views/sign-in/sign-in.component';

@NgModule({
  imports: [AuthRoutingModule, MaterialModule, SharedModule],
  declarations: [
    SignOutSuccessComponent,
    SignInFailedComponent,
    ForbiddenPageComponent,
    NoTenantErrorComponent,
    SeeAllTenantUnsupportedPageComponent,
    SignInComponent,
  ],
  providers: [
    RolesService,
    UserService,
    RoleGuard,
    RoleLoaderGuard,
    SeeAllTenantGuard,
    RolesResolver,
    TenantLoaderGuard,
  ],
})
export class AuthModule {}
