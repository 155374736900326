import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

@Component({
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
  /** Indicates if the request is currently in progress or not. */
  public requestInFlight = false;

  constructor(
    public readonly msalService: MsalService,
    private readonly router: Router
  ) {}

  ngOnInit() {
    /**
     * If we have an active account, aka we are logged in we just redirect to home page.
     * In some cases a messed up login can lead to having an authenticated list of users but no
     * active user being selected. To handle both cases we do the following here:
     * - check for active user if it exists and be happy if we found one - it means we are successfully logged in
     * - check for authenticated but not active user, in that case we select the first user from the list and hope it can access the app
     */

    const activeUser = this.msalService.instance.getActiveAccount();
    const authenticatedUserList = this.msalService.instance.getAllAccounts();

    if (activeUser || authenticatedUserList.length > 0) {
      console.warn('User already logged in, redirecting to home page.');

      if (activeUser) {
        void this.router.navigate(['']);

        return;
      }

      this.msalService.instance.setActiveAccount(authenticatedUserList[0]);
      void this.router.navigate(['']);
    }
  }

  public async login() {
    if (this.requestInFlight) return;

    this.requestInFlight = true;

    /**
     * We need to await this promise to clean any possible redirection metadata from previous failed attempts.
     * Otherwise we may receive "interaction_in_progress" errors.
     *
     * See more at https://stackoverflow.com/q/66405214/2019689.
     */
    await this.msalService.instance.initialize();
    await this.msalService.instance.handleRedirectPromise();

    await this.msalService.instance.loginRedirect();
    this.requestInFlight = false;
  }
}
