<app-anonymous-view-box-component>
  <div class="content">
    <h1>Successful sign out</h1>
    <p>
      You have successfully signed out of the application. You can go back to the login page via clicking the button
      below.
    </p>
    <a mat-button [routerLink]="['/auth/sign-in']" color="primary">GO TO LOGIN PAGE</a>
  </div>
</app-anonymous-view-box-component>
