import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { RolesService } from '../services';

/**
 * Checks roles of authenticated user and prevents navigation.
 * If user does not have access to desired page, it redirects to an informational page.
 */
@Injectable()
export class RoleLoaderGuard {
  constructor(
    private readonly roleService: RolesService,
    private readonly router: Router
  ) {}

  async canActivateChild() {
    try {
      await this.roleService.getRolesPromise();
      return true;
    } catch (e) {
      return this.router.parseUrl('auth/forbidden');
    }
  }
}
