import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TenantService } from '../../../services';

/** Ensures the user tenant list had fetched on view activation. */
@Injectable()
export class TenantLoaderGuard {
  constructor(
    private readonly tenantService: TenantService,
    private readonly router: Router
  ) {}

  async canActivateChild() {
    try {
      await this.tenantService.getTenantsPromise();
      return true;
    } catch (e) {
      return this.router.parseUrl('no-tenant-error');
    }
  }
}
