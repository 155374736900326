import { DestroyRef, inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { filter } from 'rxjs';
import { EventMessage, EventType } from '@azure/msal-browser';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable()
export class LoggedInGuard {
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private readonly msalService: MsalService,
    private readonly router: Router,
    private readonly msalBroadcastService: MsalBroadcastService
  ) {
    this.msalBroadcastService.msalSubject$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE)
      )
      .subscribe({
        next: () => {
          void this.msalService.instance.clearCache();
        },
      });
  }

  async canActivate() {
    await this.msalService.instance.initialize();
    await this.msalService.instance.handleRedirectPromise();

    const noActiveAccountSelected = !this.msalService.instance.getActiveAccount();
    const authenticatedAccountsExist = this.msalService.instance.getAllAccounts().length > 0;

    /**
     * If we have authenticated accounts but not an active account then we set
     * the first one as active.
     *
     * TODO: This is a naive solution I don't know what happens when multiple accounts are authenticated.
     */
    if (noActiveAccountSelected && authenticatedAccountsExist) {
      this.msalService.instance.setActiveAccount(this.msalService.instance.getAllAccounts()[0]);
    }

    /**
     * If we are not logged in with our Microsoft account, we redirect back to the sign in page.
     */
    if (this.msalService.instance.getActiveAccount() === null) {
      void this.router.navigate(['auth', 'sign-in']);
    }

    return true;
  }
}
